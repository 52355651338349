<template>
  <ul
    class="accordion px-md-4 mb-0"
    aria-multiselectable="true"
    :class="ptFirstClass"
  >
    <li
      class="parent-menu py-3 px-7 p-md-0"
      :class="{ 'index-title': isHeader }"
    >
      <span>メインメニュー</span>
    </li>
    <li>
      <a
        :href="eventPath"
        class="waves-effect d-block main-menu"
        :class="[attachedClass, { active: addActiveClass(['events'], ['index']) }]"
      >
        {{ t('event') }}
      </a>
    </li>
    <li>
      <a
        :href="caseStudiesIndexpath"
        class="waves-effect d-block main-menu"
        :class="[attachedClass, { active: addActiveClass(['case_studies'], ['index']) }]"
      >
        {{ t('manage_case_study') }}
      </a>
    </li>
    <li>
      <a
        class="waves-effect d-block main-menu collapsible-header arrow-r"
        href="#"
        :class="[attachedClass, { active: isActiveParentCategory('cs_assessments') || isActiveCsAssessment }]"
        @click="switchCollapseOne"
      >
        CS調査
        <i
          class="material-icons rotate-icon md-grey float-right"
          :class="{ 'reverse-icon': isCollapseOne }"
        >keyboard_arrow_down</i>
      </a>
      <div
        class="collapse mb-0 mb-md-4"
        :class="{ show: isCollapseOne }"
      >
        <ul>
          <li>
            <a
              :href="homeIntroductionPath"
              class="waves-effect d-block sub-menu"
              :class="[subAttachedClass, { active: addActiveClass(['home'], ['introduction']) }]"
              @mouseover="onMouseOver('csAssessment')"
              @mouseout="onMouseOut('csAssessment')"
            >
              {{ t('top') }}
            </a>
          </li>
          <li>
            <a
              :href="projectsIndexPath"
              class="waves-effect d-block sub-menu"
              :class="[subAttachedClass, { active: addActiveClass(['projects'], ['index']) }]"
              @mouseover="onMouseOver('csAssessment')"
              @mouseout="onMouseOut('csAssessment')"
            >
              {{ t("msg.index", { v: t("cs_survey") }) }}
            </a>
          </li>
          <li>
            <a
              :href="csAssessmentsIndexPath"
              class="waves-effect d-block sub-menu"
              :class="[subAttachedClass, { active: addActiveClass(['cs_assessments'], ['index']) }]"
              @mouseover="onMouseOver('csAssessment')"
              @mouseout="onMouseOut('csAssessment')"
            >
              {{ t("cs_assessment_index") }}
            </a>
          </li>
          <li>
            <a
              :href="csAssessmentsProjectReviewPath"
              class="waves-effect d-block sub-menu"
              :class="[subAttachedClass, { active: addActiveClass(['cs_assessments'], ['project_review']) }]"
              @mouseover="onMouseOver('csAssessment')"
              @mouseout="onMouseOut('csAssessment')"
            >
              {{ t("project") + t("review") }}
            </a>
          </li>
          <li>
            <a
              :href="csAssessmentsScoresPath"
              class="waves-effect d-block sub-menu"
              :class="[subAttachedClass, { active: addActiveClass(['cs_assessments'], ['scores']) }]"
              @mouseover="onMouseOver('csAssessment')"
              @mouseout="onMouseOut('csAssessment')"
            >
              {{ t("score_report") }}
            </a>
          </li>
          <li>
            <a
              :href="csAssessmentsVoicesPath"
              class="waves-effect d-block sub-menu"
              :class="[subAttachedClass, { active: addActiveClass(['cs_assessments'], ['voices']) }]"
              @mouseover="onMouseOver('csAssessment')"
              @mouseout="onMouseOut('csAssessment')"
            >
              {{ t("user_voice") }}
            </a>
          </li>
          <li>
            <a
              :href="csAssessmentsAnswerersPath"
              class="waves-effect d-block sub-menu"
              :class="[subAttachedClass, { active: addActiveClass(['cs_assessments'], ['answerers']) }]"
              @mouseover="onMouseOver('csAssessment')"
              @mouseout="onMouseOut('csAssessment')"
            >
              {{ t("answerer") + t("list") }}
            </a>
          </li>
          <li>
            <a
              :href="csAssessmentsCustomerCompanies"
              class="waves-effect d-block sub-menu"
              :class="[subAttachedClass, { active: addActiveClass(['customer_companies'], ['index']) }]"
              @mouseover="onMouseOver('csAssessment')"
              @mouseout="onMouseOut('csAssessment')"
            >
              {{ t("customer_company") + t("list") }}
            </a>
          </li>
          
        </ul>
      </div>
    </li>
    <li
      class="parent-menu py-3 px-7 pt-md-9 pt-md-0 px-md-0"
      :class="{ 'index-title': isHeader }"
    >
      <span>管理メニュー</span>
    </li>
    <li>
      <a
        class="waves-effect d-block main-menu collapsible-header arrow-r"
        href="#"
        :class="[attachedClass, { active: isActiveParentCategory('company') || isActiveCompanyEdit }]"
        @click="switchCollapseTwo"
      >
        {{ t('company_information') }}
        <i
          class="material-icons rotate-icon md-grey float-right"
          :class="{ 'reverse-icon': isCollapseTwo }"
        >keyboard_arrow_down</i>
      </a>
      <div
        class="collapse"
        :class="{ show: isCollapseTwo }"
      >
        <ul>
          <li>
            <a
              :href="companiesEditPath"
              class="waves-effect d-block sub-menu"
              :class="[subAttachedClass, { active: addActiveClass(['companies'], ['edit', 'update']) }]"
              @mouseover="onMouseOver('company')"
              @mouseout="onMouseOut('company')"
            >
              {{ t("base_info") }}
            </a>
          </li>
          <li>
            <a
              :href="companyDetailsEditPath"
              class="waves-effect d-block sub-menu"
              :class="[subAttachedClass, { active: addActiveClass(['company_details'], ['edit', 'update']) }]"
              @mouseover="onMouseOver('company')"
              @mouseout="onMouseOut('company')"
            >
              {{ t("company_business_specialty") }}
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li>
      <a
        :href="usersIndexPath"
        class="waves-effect d-block main-menu"
        :class="[attachedClass, { active: addActiveClass(['users'], ['index']) }]"
      >
        {{ t("user_admin") }}
      </a>
    </li>
    <li>
      <a
        :href="companyShowPlanPath"
        class="waves-effect d-block main-menu"
        :class="[attachedClass, { active: addActiveClass(['companies'], ['show_plan']) }]"
      >
        {{ t("company_show_plan") }}
      </a>
    </li>
    <li class="d-block d-md-none">
      <a
        :href="notificationsIndexPath"
        class="waves-effect d-block main-menu"
        :class="[attachedClass, { active: addActiveClass(['notifications'], ['index', 'show']) }]"
      >
        {{ t("notification") }}
        <span
          v-if="unreadNotificationCount > 0"
          class="notification-badge font-extrasmall"
        >
          {{ unreadNotificationCount }}
        </span>
      </a>
    </li>
    <li
      v-if="isHeader"
      class="d-block main-menu bg-gray01 md-dark"
      :class="attachedClass"
      @click="closeMenu"
    >
      <div
        class="d-flex justify-content-center"
      >
        {{ t('close') }}
        <i class="material-icons md-dark ml-3">
          close
        </i>
      </div>
    </li>
  </ul>
</template>


<script>
import "../i18n/ja";
import { i18n } from "../mixins/i18n";

export default {
  mixins: [i18n],
  props: {

    // HACK: isHeaderわかりにくいので名前を変えるべき
    isHeader: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: Number,
      required: true
    },
    companyDetailId: {
      type: Number,
      required: true
    },
    controllerName: {
      type: String,
      required: true
    },
    actionName: {
      type: String,
      required: true
    },
    unreadNotificationCount: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isActiveCsAssessment: null,
      isActiveCompanyEdit: null,
      rootPath: "/",
      homeIntroductionPath: "/admin/home/introduction",
      usersIndexPath: "/admin/users",
      companiesEditPath: "/admin/company/edit",
      companyDetailsEditPath: "/admin/company_detail/edit",
      companyShowPlanPath: "/admin/company/plan",
      notificationsIndexPath: "/admin/notifications",
      caseStudiesIndexpath: "/admin/case_studies",
      eventPath: "/admin/events",
      projectsIndexPath: "/admin/projects",
      csAssessmentsIndexPath: "/admin/cs_assessments/",
      csAssessmentsProjectReviewPath: "/admin/cs_assessments/project_review",
      csAssessmentsScoresPath: "/admin/cs_assessments/scores",
      csAssessmentsVoicesPath: "/admin/cs_assessments/voices",
      csAssessmentsAnswerersPath: "/admin/cs_assessments/answerers",
      csAssessmentsCustomerCompanies: "/admin/customer_companies",
      isCollapseOne: false,
      isCollapseTwo: false
    };
  },

  // # HACK: メディアクエリで切り替えればisHeaderいらないのでは？
  // できるならcustom_stylesでmdbのハンバーガーメニューのcssに直接指定する方が良い
  computed: {
    attachedClass() {
      return this.isHeader ? "py-3 px-7 border-bottom bg-white header-sub-title" : "pt-3";
    },
    subAttachedClass() {
      return this.isHeader ? "py-3 px-10 border-bottom bg-white header-sub-title" : "pt-3";
    },
    ptFirstClass() {
      return this.isHeader ? "scrollable-accordion" : "pt-6 pb-8";
    }
  },
  created() {
    if (this.isActiveParentCategory("cs_assessments")) {
      this.isCollapseOne = true;
    }
    if (this.isActiveParentCategory("company")) {
      this.isCollapseTwo = true;
    }
  },
  methods: {
    isActiveParentCategory(parentName) {
      if (parentName === "cs_assessments") {
        return this.addActiveClass(["cs_assessments"], ["index", "project_review", "scores", "voices", "answerers", "customer_companies"]) || this.addActiveClass(["customer_companies"], ["index"]) || this.addActiveClass(["home"], ["introduction"]) || this.addActiveClass(["projects"], ["index"]);
      }
      if (parentName === "company") {
        return this.addActiveClass(["companies", "company_details"], ["edit"]);
      }
      return false;
    },
    addActiveClass(controllers, actions) {
      return controllers.includes(this.controllerName) && actions.includes(this.actionName);
    },
    onMouseOver(type) {
      if (type === "csAssessment") {
        this.isActiveCsAssessment = true;
        return;
      }
      this.isActiveCompanyEdit = true;
    },
    onMouseOut(type) {
      if (type === "csAssessment") {
        this.isActiveCsAssessment = false;
        return;
      }
      this.isActiveCompanyEdit = false;
    },
    closeMenu() {
      this.$emit("close");
    },
    switchCollapseOne() {
      this.isCollapseOne = !this.isCollapseOne;
    },
    switchCollapseTwo() {
      this.isCollapseTwo = !this.isCollapseTwo;
    }
  }
};
</script>

<style scoped>
.top-link {
  background: #F7F8FA !important;
}
.index-title {
  background: #B8C2CC;
  color: #FFFFFF;
}
.header-sub-title {
  color: #5F6976
}
.reverse-icon{
  transform:rotate(180deg);
}
.notification-badge {
  background-color: #ff9330;
  color: #fff;
  min-width: 16px;
  height: 16px;
  padding: 0 4.5px;
  text-align: center;
  line-height: 14px;
  border-radius: 10px;
}
</style>
