<template>
  <div class="icon-menu">
    <a
      class="vertical-middle"
      @click="toggleMenu"
    >
      <i class="user-icon material-icons text-gray03">
        <div class="menu-active-circle" />
        account_circle
      </i>
    </a>
    <ul class="user-menu">
      <li class="mb-0 mb-md-4">
        <a
          href="/users/edit"
          class="d-block text-dark-gray"
          target="_blank"
        >
          {{ t('edit_profile') }}
        </a>
      </li>
      <li class="mb-0 mb-md-4">
        <a
          href="/users/profile/edit_email"
          class="d-block text-dark-gray"
          target="_blank"
        >
          {{ t('edit_email') }}
        </a>
      </li>
      <li class="mb-0 mb-md-4">
        <a
          href="/users/profile/edit_password"
          class="d-block text-dark-gray"
          target="_blank"
        >
          {{ t('edit_password') }}
        </a>
      </li>
      <li class="mb-0 mb-md-4">
        <a
          href="/admin/email_preferences"
          class="d-block text-dark-gray"
          target="_blank"
        >
          {{ t('email_notification_preferences') }}
        </a>
      </li>
      <li
        v-if="isMainAccount"
        class="mb-0 mb-md-4"
      >
        <a
          href="/users/quit"
          class="d-block text-dark-gray"
        >
          {{ t('cancel_membership_application') }}
        </a>
      </li>
      <li>
        <a
          href="/users/sign_out"
          data-method="delete"
          class="d-block text-dark-gray"
        >
          {{ t('sign_out') }}
        </a>
      </li>
      <li
        class="d-block main-menu bg-gray01 md-dark d-md-none"
        @click="closeMenu"
      >
        <div class="d-flex justify-content-center">
          {{ t('close') }}
          <i class="material-icons md-dark ml-3">
            close
          </i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import "../i18n/ja";
import { i18n } from "../mixins/i18n";

export default {
  mixins: [i18n],
  props: {
    isMainAccount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hideOverlay: true
    };
  },
  methods: {
    toggleMenu() {
      this.$emit("toggle");
    },
    closeMenu() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-menu {
  position: relative;

  .user-icon {
    position: relative;
    font-size: 42px !important;

    .menu-active-circle {
      border: 3px solid #0080FC;
      border-radius: 50%;
      width: 85%;
      height: 85%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
  }
  .user-menu {
    display: none;
    position: absolute;
    top: 65px;
    right: -25px;
    width: 200px;
    background-color: #fff;
    padding: 25px;
    z-index: 10;
    border-top: solid 1px #DBDEE1;

    li {
      line-height: 17px;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 767px) {
    & {
      position: static;

      .user-menu {
        width: 100%;
        left: 0;
        padding: 0;
        top: 79px;

        li {
          line-height: 22px;
          padding: 16px 30px;
          border-bottom: solid 1px #DBDEE1;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
