import Vue from "vue/dist/vue.esm";


// /components/usages
import UsageSideNav from "./components/usages/UsageSideNav.vue";

// /components
import AdminSideNav from "components/AdminSideNav.vue";
import UserIcon from "components/UserIcon.vue";

// /components/notifications
import NotificationCount from "components/notifications/NotificationCount.vue";
import DropDownForHeaderNav from "components/DropDownForHeaderNav.vue";
import DropDownForHamburgerMenu from "components/DropDownForHamburgerMenu.vue";


export default new Vue({
  el: "#header-app",
  components: {
    UsageSideNav,
    AdminSideNav,
    NotificationCount,
    UserIcon,
    DropDownForHeaderNav,
    DropDownForHamburgerMenu
  },
  data() {
    return {
      showOverlay: false,
      menuButtonIcon: "menu",
      hamburgerMenuId: "#navbarSupportedContent"
    };
  },
  methods: {
    toggleUserMenu() {
      if (this.isUserMenuOpened()) {
        this.closeUserMenu();
      } else {
        this.openUserMenu();
      }
    },
    toggleHamburgerMenu() {
      if (this.isHamburgerMenuOpened()) {
        this.closeHamburgerMenu();
      } else {
        this.openHamburgerMenu();
      }
    },
    openUserMenu() {
      if (this.isHamburgerMenuOpened()) {
        this.closeHamburgerMenu();
      }
      this.showOverlay = true;
      $(".user-menu").slideDown();
      $(".icon-menu").addClass("show");
      $(".menu-active-circle").fadeIn();
    },
    openHamburgerMenu() {
      if (this.isUserMenuOpened()) {
        this.closeUserMenu();
      }
      this.showOverlay = true;
      this.menuButtonIcon = "close";
      $(this.hamburgerMenuId).collapse("show");
    },
    closeUserMenu() {
      this.showOverlay = false;
      $(".user-menu").slideUp();
      $(".icon-menu").removeClass("show");
      $(".menu-active-circle").fadeOut();
    },
    closeHamburgerMenu() {
      this.showOverlay = false;
      this.menuButtonIcon = "menu";
      $(this.hamburgerMenuId).collapse("hide");
    },
    closeMenu() {
      this.showOverlay = false;
      this.closeHamburgerMenu();
      this.closeUserMenu();
    },
    isHamburgerMenuOpened() {
      return $(this.hamburgerMenuId).hasClass("show");
    },
    isUserMenuOpened() {
      return $(".icon-menu").hasClass("show");
    }
  }
});
