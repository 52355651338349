<!-- こちらプレスリリース時に作成したコンポーネントです
MDBをなくすタイミング等で使用できるものなので残しておいてください！
利用用途：SP時のハンバーガーメニュー内でプルダウンをつけたい箇所に使用 -->
<template>
  <li
    class="clickable-area"
    @click.self="open"
  >
    <span
      :class="titleColor"
      @click.self="open"
      class="d-block py-3 px-7 border-bottom bg-white"
    >
      {{ dropDownTitle }}
      <i
        class="material-icons float-right"
        :class="[arrowIconColor, { 'reverse-icon': isOpen }]"
        @click.self="open"
      >
        keyboard_arrow_down
      </i>
    </span> 
    <ul
      :class="{ 'd-none' : !isOpen }"
    >
      <li
        v-for="item in dropDownContent"
        :key="item.contentName"
        class="d-block main-menu py-3 pr-7 pl-10 border-bottom bg-white"
      >
        <a
          :href="item.contentUrl"
          :key="item.contentName"
        >
          {{ item.contentName }}
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    dropDownTitle: {
      type: String,
      default: ""
    },
    dropDownContent: {
      type: Array,
      default: [
        {
          contentName: "",
          contentUrl: ""
        }
      ]
    },
    arrowIconColor: {
      type: String,
      default: "text-dark-gray"
    },
    titleColor: {
      type: String,
      default: ""
    }
  },
  methods: {
    open() {
      this.isOpen = !this.isOpen;
    }
  }
}
</script>

<style lang="scss" scoped>
.drop-down-link {
  &:hover {
    opacity: .6;
  }
}
.clickable-area {
  &:hover{
    cursor: pointer;
  }
}
.reverse-icon{
  transform:rotate(180deg);
}
</style>
