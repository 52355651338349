<template>
  <div>
    <ul
      class="main-ul"
      :class="attachedUlClass"
    >
      <li class="main-li">
        <a
          class="waves-effect d-block main-link"
          :class="[{ active: isActive('/admin/usages/cs_evaluation_request') }, attachedLiClass]"
          href="/admin/usages/cs_evaluation_request"
        >
          {{ t("customer_satisfaction_with_en") + t("evaluation_request") }}
        </a>
        <ul
          v-if="deviceType === 'PC' && isActive('/admin/usages/cs_evaluation_request')"
          class="sub-ul"
        >
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link current"
              href="/admin/usages/cs_evaluation_request#step1"
            >
              {{ t("usage.cs_evaluation_request.step1") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_evaluation_request#step2"
            >
              {{ t("usage.cs_evaluation_request.step2") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_evaluation_request#step3"
            >
              {{ t("usage.cs_evaluation_request.step3") }}
            </a>
          </li>
        </ul>
      </li>
      <li class="main-li">
        <a
          class="waves-effect d-block main-link"
          :class="[{ active: isActive('/admin/usages/cs_evaluation') }, attachedLiClass]"
          href="/admin/usages/cs_evaluation"
        >
          {{ t("customer_satisfaction_with_en") + t("evaluation") }}
        </a>
        <ul
          v-if="deviceType === 'PC' && isActive('/admin/usages/cs_evaluation')"
          class="sub-ul"
        >
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link current"
              href="/admin/usages/cs_evaluation#step1"
            >
              {{ t("usage.cs_evaluation.step1") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_evaluation#step2"
            >
              {{ t("usage.cs_evaluation.step2") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_evaluation#step3"
            >
              {{ t("usage.cs_evaluation.step3") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_evaluation#step4"
            >
              {{ t("usage.cs_evaluation.step4") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_evaluation#step5"
            >
              {{ t("usage.cs_evaluation.step5") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_evaluation#step6"
            >
              {{ t("usage.cs_evaluation.step6") }}
            </a>
          </li>
        </ul>
      </li>
      <li class="main-li">
        <a
          class="waves-effect d-block main-link"
          :class="[{ active: isActive('/admin/usages/cs_publication_request') }, attachedLiClass]"
          href="/admin/usages/cs_publication_request"
        >
          {{ t("customer_satisfaction_with_en") + t("request_publish") }}
        </a>
        <ul
          v-if="deviceType === 'PC' && isActive('/admin/usages/cs_publication_request')"
          class="sub-ul"
        >
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link current"
              href="/admin/usages/cs_publication_request#step1"
            >
              {{ t("usage.cs_publication_request.step1") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_publication_request#step2"
            >
              {{ t("usage.cs_publication_request.step2") }}
            </a>
          </li>
        </ul>
      </li>
      <li class="main-li">
        <a
          class="waves-effect d-block main-link"
          :class="[{ active: isActive('/admin/usages/cs_publication') }, attachedLiClass]"
          href="/admin/usages/cs_publication"
        >
          {{ t("customer_satisfaction_with_en") + t("public") }}
        </a>
        <ul
          v-if="deviceType === 'PC' && isActive('/admin/usages/cs_publication')"
          class="sub-ul"
        >
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link current"
              href="/admin/usages/cs_publication#step1"
            >
              {{ t("usage.cs_publication.step1") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_publication#step2"
            >
              {{ t("usage.cs_publication.step2") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_publication#step3"
            >
              {{ t("usage.cs_publication.step3") }}
            </a>
          </li>
          <li class="sub-li">
            <a
              class="waves-effect d-block sub-link"
              href="/admin/usages/cs_publication#step4"
            >
              {{ t("usage.cs_publication.step4") }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import I18n from "../../i18n/i18n";
import "../../i18n/ja";
import { i18n } from "../../mixins/i18n";

export default {
  mixins: [i18n],
  props: {
    deviceType: {
      type: String,
      default: "PC"
    }
  },
  data() {
    return {
      attachedUlClass: "pt-6 pb-8",
      attachedLiClass: "py-1",
      href: location.href,
      scrollY: window.pageYOffset,
      heightsArray: [],
      subLink: document.getElementsByClassName("sub-link"),
      current: document.getElementsByClassName("current")
    };
  },
  mounted() {
    this.href = this.href.replace(/http.*?\/admin/, "/admin").replace(/#.*?$/, "");

    if (this.deviceType === "SP") {
      this.attachedUlClass = "";
      this.attachedLiClass = "py-3 px-3 border-bottom";
    }

    if (this.deviceType === "PC") {
      window.addEventListener("load", this.setBoundary);
      window.addEventListener("scroll", this.calculateScrollY);
      window.addEventListener("scroll", this.isCurrent);
      window.addEventListener("resize", this.isCurrent);
      window.addEventListener("resize", this.setBoundary);
    }
  },
  beforeDestroy() {
    if (this.deviceType === "PC") {
      window.removeEventListener("load", this.setBoundary);
      window.removeEventListener("scroll", this.calculateScrollY);
      window.removeEventListener("scroll", this.isCurrent);
      window.removeEventListener("resize", this.isCurrent);
      window.removeEventListener("resize", this.setBoundary);
    }
  },
  methods: {
    isActive(url) {
      return this.href === url;
    },
    calculateScrollY() {
      this.scrollY = window.pageYOffset;
    },
    setBoundary() {
      const stepObj = document.getElementsByClassName("usage-step");

      for (let i = 0; i < stepObj.length; i++) {
        this.heightsArray[i] = stepObj[i].getBoundingClientRect().top + window.pageYOffset - 1;
      }
    },
    isCurrent() {
      if (this.current.length !== 0) {
        this.current[0].classList.remove("current");
      }

      if (this.scrollY < this.heightsArray[0]) {
        this.subLink[0].classList.add("current");
      } else if (this.scrollY >= this.heightsArray[this.heightsArray.length - 1]) {
        this.subLink[this.heightsArray.length - 1].classList.add("current");
      } else {
        for (let i = 0; i < this.heightsArray.length; i++) {
          if (this.scrollY >= this.heightsArray[i] && this.scrollY < this.heightsArray[i + 1]) {
            this.subLink[i].classList.add("current");
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-ul {
  margin-bottom: 0;
  .main-li {
    margin: 0 20px;
    .main-link {
      color: #8a929a;
      font-size: 0.857rem;
      position: relative;
      border: 1px solid transparent;
      &.active {
        padding-bottom: 10px;
        background-color: #001b5a;
        color: #0080fc;
        i {
          color: #0080fc;
        }
      }
      &:hover {
        color: #0080fc;
        background-color: #001b5a;
      }
    }
    .sub-ul {
      margin: 2px 0 40px 10px;
      .sub-li {
        margin-bottom: 10px;
        .sub-link {
          padding-left: 10px;
          color: #ffffff;
          font-size: 0.857rem;
          transition: none;
          &.current {
            padding-left: 8px;
            border-left: 2px solid #0080fc;
          }
          &:hover {
            padding-left: 8px;
            border-left: 2px solid #0080fc;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .main-ul {
    .main-li {
      margin: 0;
      background-color: #001b5a;
      .main-link {
        padding: 1.5rem 2rem;
        font-size: 1rem;
        &.active {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
