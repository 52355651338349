<template>
  <ul
    :class="ulMarginBottom"
    class="h-100"
  >
    <li 
      class="clickable-area d-flex h-100"
      @click="open"
      @mouseleave="mouseOutClose"
    >
      <span
        :class="{ 'text-commit-blue': isOpen }"
        class="align-self-center"
      >
        {{ dropDownTitle }}
        <i
          class="material-icons ml-1"
          :class="[arrowIconColor, { 'reverse-icon': isOpen }]"
        >
          keyboard_arrow_down
        </i>
        
      </span>
      <transition name="dropdown">
        <ul
          v-show="isOpen"
          class="drop-down-content-box"
          :style="{ width: contentWidth + 'px' }"
        >
          <li
            v-for="(item, i) in dropDownContent"
            :key="item.contentName"
            :class="[i != dropDownContent.length - 1 ? 'pb-4' : '']"
          >
            <a
              :href="item.contentUrl"
              :key="item.contentName"
              class="d-block"
            >
              {{ item.contentName }}
            </a>
          </li>
        </ul>
      </transition>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    dropDownTitle: {
      type: String,
      default: ""
    },
    dropDownContent: {
      type: Array,
      default: [
        {
          contentName: "",
          contentUrl: ""
        }
      ]
    },
    arrowIconColor: {
      type: String,
      default: "text-gray03"
    },
    ulMarginBottom: {
      type: String,
      default: ""
    },
    contentWidth: {
      type: String,
      default: ""
    }
  },
  methods: {
    open() {
      this.isOpen = !this.isOpen;
    },
    mouseOutClose() {
      this.isOpen = false;
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  &:hover {
    color: #0080fc;
    transition: all .2s ease-in-out;
  }
}
.clickable-area {
  &:hover{
    cursor: pointer;
  }
}
.reverse-icon{
  transform:rotate(180deg);
}
.drop-down-content-box {
  position: absolute;
  top: calc(100% + 1px);
  left: -21px;
  border-radius: 3px;
  padding: 25px;
  background-color: #fff;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, .15);
  z-index: 3;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all .2s;
}

.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
