<template>
  <a
    class="notification-badge-icon"
    href="/admin/notifications"
  >
    <i class="material-icons mx-4 pt-1 text-dark-gray">
      info
    </i>
    <span
      v-if="unreadNotificationCount > 0"
      class="notification-badge font-extrasmall"
    >
      {{ unreadNotificationCount }}
    </span>
    <p
      v-if="labelFlg"
      class="mx-1 mb-0 font-small text-dark-gray"
      :class="displayLabelForSp"
    >
      {{ t("notification") }}
    </p>
  </a>
</template>

<script>
import "../../i18n/ja";
import { i18n } from "../../mixins/i18n";

export default {
  mixins: [i18n],
  props: {
    unreadNotificationCount: {
      type: String,
      default: ""
    },
    labelFlg: {
      type: Boolean,
      default: false
    },
    labelSpFlg: {
      // 「お知らせ」ラベルがsp時にも必要な場合はtrue
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayLabelForSp() {
      return this.labelSpFlg ? '' : 'd-none d-sm-block'
    }
  }
};
</script>

<style scoped>
.notification-badge-icon {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 2px;
  left: 35px;
  background-color: #ff9330;
  color: #fff;
  min-width: 16px;
  height: 16px;
  padding: 0 4.5px;
  text-align: center;
  line-height: 14px;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .notification-badge {
    top: 0px;
    left: 35px;
  }
}

a:hover .text-dark-gray {
  color: #0080fc !important;
}
</style>
